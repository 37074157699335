import React, {useState, useEffect} from 'react';

import LayoutPanel from "components/layout/layoutPanel";
import Link from 'components/common/Link';
import MediaCard from 'components/common/MediaCard';

import { mermaidFlowmojiBgUrl } from 'constants/urls';

import fetchBlogData from 'constants/fetchBlogData';

// style
// bespoke styles for this page
// https://www.gatsbyjs.com/docs/how-to/styling/css-modules
import 'styles/components/cards/blog-grid.css';

function BlogGrid (props) {

	const [blogPosts, setPosts] = useState([]);
    useEffect(() => {

    	let apiQuery = (props.apiQuery) ? props.apiQuery : '';

        const fetchData = async () => {
            setPosts( await fetchBlogData(`/apis/seahorse/blogs/${apiQuery}`));
        };
     
        fetchData();
    }, []);

	return (
		<>
			{ !!(Array.isArray(blogPosts) && blogPosts.length && blogPosts.length>0) &&
				<>
					<LayoutPanel col="blog-grid__head"
	 			                 background={props.panelBackground}
                     			 padding={props.panelPadding} 
                 				 {...props}
	 			                 id="stories">
			            <div className="container">
			                <div className="row align-items-center justify-content-center">
					            <div className="col-12 col-sm-8 col-lg-6 mer-text--size-lg">
					                <div className="content text-center">
					                    <h2 className="mb-0">{props.title}</h2>
					                    <h5 className="mt-2">{props.description}</h5>
					                </div>
					            </div>
					        </div>
			            </div>
			        </LayoutPanel>

					<LayoutPanel background="mer-theme--light mer-bg--ui-light"
								 col="blog-grid__body"
	                    		 padding="mer-pt-sm mer-pb-lg"
	                    		 inlineStyle={{ backgroundImage: `url(${mermaidFlowmojiBgUrl})`,
	                    		 				backgroundRepeat: 'repeat',
	                    		 				backgroundSize: '60vh' }}>
	                
		                <div className="container">
		                    <div className="row justify-content-center blog-grid__cards">
			                	{/* media cards */}
			                	{blogPosts.map((media, blogIndex) => {
			                		
			                        return (
			                        	<div className="col-10 col-sm-6 col-lg-4" 
			                        		 key={blogIndex}>
				                            <MediaCard {...media}
						                               id={blogPosts.id}
						                               hideDescription={true}
						                               hideCta={true}
						                               orientation="grid"
						                               isCardSameHeight={true}
						                               disableWidescreen={true}
				                            />
					                    </div>
			                        )
			                    })}
		                    </div>
		                </div>

		                <div className="container">
		                    <div className="row justify-content-center mer-pt-de">
		                        <div className="col-10 content text-center">
		                        	<h5>
		                            	<Link text="Find more stories on our blog" 
		                                	  link="/blog" />
		                            </h5>
		                        </div>
		                    </div>
		                </div>
		            </LayoutPanel>
		        </>
			}
		</>
	);
}

export default BlogGrid;