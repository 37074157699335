import React from 'react';

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from "components/layout/layoutMain";
import LayoutPanel from "components/layout/layoutPanel";
import Panel from "components/common/Panel";

import HomepageHeroBanner from 'components/cards/HomepageHeroBanner';
import BlogGrid from 'components/cards/BlogGrid';

import { Svg } from 'kh-common-components';

import { modalContents } from 'constants/modal-content';
import panelData from 'constants/thinking-gas/panel';

// asset images
// https://www.gatsbyjs.com/docs/how-to/images-and-media/importing-assets-into-files/
import heroCake35 from 'images/hero-cake35.png';
import heroSkateboardBoxes from 'images/hero-skateboardboxes.png';
import hero45kgSave10 from 'images/hero-45kg-save10.png';
import ntagDarkComp from 'images/thinking-gas/NTAG_rev_darkblue.svg';

function WinterCampaign2021LandingPage() {
	return (
		<>
            <SEO title="Thinking about gas? Think Kleenheat." meta={[{name:"robots", content:'noindex'}]} />
            <Header />
            <LayoutMain>

	    		<HomepageHeroBanner />
	    		<LayoutPanel background="mer-bg--gs-grey-lighter mer-theme--light"
			                 border="mer-border--bottom"
			                 padding="mer-py-sm">
		            <div className="container">
		                <div className="row justify-content-around">
				            <div className="col-6 col-md-12">
				                <div className="content">
				                    <div className="d-flex justify-content-center align-items-center">
				                    	<Svg title="Flowmoji sunglasses" 
		                                     col="mer-svg-container--size-xl-96 mer-mr-sm d-none d-sm-block my-n4"
		                                     icon="flowmoji-sunglasses"
		                                /> 
				                        <h5 className="my-2">Not thinking about gas? <a href="#stories" className="mer-text--no-wrap">We've still got plenty to keep you interested.</a></h5>
				                    </div>
				                </div>
				            </div>
				        </div>
		            </div>
		        </LayoutPanel>

		        <LayoutPanel background="mer-bg--ui-light"
			                 padding="mer-py-lg">

		                <div className="container">
		                	<div className="row align-items-center justify-content-center">
		                		<div className="col-10 col-sm-8 col-lg-6 mer-text--size-lg">
					                <div className="content text-center">
					                	<h1 className="mer-color--primary-cyan">
					                    	For those thinking <span className="mer-text--no-wrap">about gas moments.</span>
					                    </h1>
					                </div>
					            </div>

					            <div className="col-10">
					            	<Panel data={panelData} 
						                   id="panel-1"
						                   imgLocal={heroCake35}
									/>
									<Panel data={panelData} 
						                   id="panel-2"
						                   imgLocal={heroSkateboardBoxes}
									/>
									<Panel data={panelData} 
						                   id="panel-3"
						                   imgLocal={hero45kgSave10}
						                   modalTitle="Save 10% when you order online"
						                   modalContents={modalContents}
						                   modalDisplayContent={<small>*Terms and conditions apply</small>}
						                   modalContentName="lpg_order_terms"
						                   modalOkaybuttonLabel="Okay"
									/>
					            </div>
		                	</div>
		                </div>
		        </LayoutPanel>

		        <LayoutPanel theme="mer-theme--cyan"
	        				 background="mer-bg--primary-cyan"
		                	 padding="mer-py-lg">
		            <div className="container">
		                <Panel data={panelData} 
		                       id="competition"
		                       imgLocal={ntagDarkComp} />
		            </div>
		        </LayoutPanel>

            	<BlogGrid id="stories"
            			  title="Finished thinking about gas?"
            			  description="Here's some fun stuff for you to enjoy"
            			  panelPadding="mer-pt-lg mer-pb-ju"
            			  panelBackground="mer-bg--primary-royal mer-theme--dark"
            			  apiQuery="?category=community,everyday-gas,recipes,rewards&posts_per_page=6"
    			/>

		    </LayoutMain>
			<Footer />
		</>
	)
}

export default WinterCampaign2021LandingPage